import { Component } from "react";
import siteLogo from "../assets/imgs/Peace_Marketing_Logo.png";

class Footer extends Component {
  render() {
    const navStyle = {
      display: "flex",
      listStyle: "none",
      justfiyContent: "justify-start",
      marginBottom: "0px",
    };
    const footerLogoStyle = {
      maxWidth: "64px",
      float: "right",
    };
    const footerSeperatorStyle = {
      color: "white",
      width: "80%",
    };
    let facebookUrl = "https://www.facebook.com/dragon.soft.star.forest/";
    let skypeUrl = "skype:live:silverstar710rich?chat";
    let linkedinUrl = "https://www.linkedin.com/in/jacob-davidson-73a7a7223/";

    return (
      <footer id="footer" className="bg-black pt-5 pb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8 col-sm-6 col-6 px-0">
              <ul style={navStyle} className="px-0">
                <a
                  href="tel: 15842599957"
                  className="nav-link text-white"
                  rel="noreferrer"
                  target={"_blank"}
                >
                  <i className="fa-solid fa-phone"></i> Phone: 15842599957
                </a>

                <a
                  href="mailto:zhongshun.finance.pm@outlook.com"
                  className="nav-link text-white"
                  rel="noreferrer"
                  target={"_blank"}
                >
                  <i className="fa fa-envelope"></i> Email:
                  zhongshun.finance.pm@outlook.com
                </a>
              </ul>
            </div>
            <div className="col-md-4 col-sm-6 col-6">
              <img
                src={siteLogo}
                style={footerLogoStyle}
                alt="Peace Marketing Website Development Team"
              />
            </div>
          </div>
          <div className="row pt-3 pb-3">
            <hr style={footerSeperatorStyle} className="m-auto" />
          </div>
          <div className="row ">
            <div className="col-md-12">
              <p
                id="footer-bottom-txt"
                className="text-white text-center small"
              >
                © 2019 All rights Reserved. Design by Perfect Solution.
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
