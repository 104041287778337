import { Component } from "react";
// import axios from "axios";
import "./Header.css";
import siteLogo from "../assets/imgs/Peace_Marketing_Logo.png";
import { send } from "emailjs-com";
import { Link } from "react-router-dom";
import { Row, Col, Menu } from "antd";

const { SubMenu } = Menu;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      hide: false,
    };
  }
  state = {
    subscribeRes: "",
  };

  menuHide() {
    this.setState({ hide: true });
    alert(this.state.hide);
  }
  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onSubjectChange(event) {
    this.setState({ subject: event.target.value });
  }

  onMsgChange(event) {
    this.setState({ message: event.target.value });
  }

  submitEmail(e) {
    e.preventDefault();

    // axios({
    //     method: "POST",
    //     // url: "http://localhost:3001/send",
    //     url: "https://www.react.peace-marketing.com:3001/send",
    //     data: { name: this.state.name, to: this.state.email }
    // }).then((response) => {
    //     if (response.data.status === 'success') {
    //         this.setState({ subscribeRes: 'Email sent successfully!' });
    //         this.resetForm()
    //     } else if (response.data.status === 'fail') {
    //         this.setState({ subscribeRes: 'FAILED...' });
    //     }
    // });

    send(
      "service_n003ad7",
      "template_0snm518",
      this.state,
      "user_g3IILZ4agZB3VqkwvuOlC"
    )
      .then((response) => {
        this.setState({ subscribeRes: "Email sent successfully!" });
      })
      .catch((err) => {
        this.setState({ subscribeRes: "FAILED..." + err });
      });
  }

  resetForm() {
    this.setState({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  }

  render() {
    const smallFont = {
      fontSize: "12px",
      backgroundColor: "transparent",
      height: "auto",
    };

    const menuUrl = {
      home: "#home-banner",
      about: "#home-about",
      contact: "#home-subscribe",
    };
    const { handleClick } = this.props;
    const menus = [
      {
        link: "#home-banner",
        title: "Home",
        key: "home",
      },
      {
        link: "#home-about",
        title: "About",
        key: "about",
      },
      {
        link: "#home-subscribe",
        key: "contact",
        title: "Contact Us",
      },
    ];

    let span = {
      xs: 22,
      sm: 20,
      md: 20,
      lg: 20,
      xl: 18,
      xxl: 18,
    };

    return (
      <header>
        <nav role="navigation">
          <div id="menuToggle" className="d-none d-md-none">
            <input type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <ul id="menu">
              <li className="active">
                <Link to={menuUrl.home} rel="noreferrer">
                  Home
                </Link>
              </li>
              <li>
                <Link to={menuUrl.about} rel="noreferrer">
                  About
                </Link>
              </li>
              <li>
                <Link to={menuUrl.contact} rel="noreferrer">
                  Contact
                </Link>
              </li>

              <div id="menu-bottom">
                <form
                  id="menu-form"
                  className="pt-5 pb-5"
                  onSubmit={this.submitEmail.bind(this)}
                  method="POST"
                >
                  <div className="form-group">
                    <div className="text-white">
                      <p>Book A Time To Chat</p>
                      <p>
                        <span style={smallFont}>
                          Or Stay In Touch And Get Some Of The Best Tips, Tricks
                          And Latest Updates Right In Your Inbox
                        </span>
                      </p>
                      <input
                        placeholder="Name"
                        id="menu-name"
                        type="text"
                        className="form-control"
                        aria-describedby="nameHelp"
                        required
                        value={this.state.name}
                        onChange={this.onNameChange.bind(this)}
                      />
                      <input
                        placeholder="Email"
                        id="menu-email"
                        type="email"
                        className="form-control"
                        aria-describedby="emailHelp"
                        required
                        value={this.state.email}
                        onChange={this.onEmailChange.bind(this)}
                      />
                      <button
                        type="submit"
                        className="primary-btn submit main-btn"
                      >
                        Stay In Touch!
                      </button>
                      <p className="mt-3 text-white text-center">
                        {this.state.subscribeRes}
                      </p>
                    </div>
                  </div>
                </form>
                <ul className="nav col-lg-auto my-2 justify-content-center my-md-0 text-small">
                  <li>
                    <p
                      href="tel: 8615842599957"
                      className="nav-link text-white"
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      <i className="fa-solid fa-phone"></i>Phone: +86
                      15842599957
                    </p>
                    <p
                      href="mailto:zhongshun.finance.pm@outlook.com"
                      className="nav-link text-white"
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      <i className="fa fa-envelope"></i> Email:
                      zhongshun.finance.pm@outlook.com
                    </p>
                  </li>
                </ul>
              </div>
            </ul>
          </div>
        </nav>
        <Row justify="space-between" align="top" className="container">
          <Col span={24} className="app">
            <Row>
              <Col className="header" span={24}>
                <Row justify="space-around" align="middle">
                  <Col span={3}>
                    <Link to="/">
                      <img
                        src={siteLogo}
                        className="header-logo"
                        alt="Peace Marketing IT Service logo"
                      />
                    </Link>
                  </Col>
                  <Col span={12}>
                    <Row justify="center">
                      <Col className="main_menu" span={24}>
                        <Menu
                          onClick={handleClick}
                          mode="horizontal"
                          style={{
                            backgroundColor: "transparent",
                            textAlign: "center",
                            borderBottom: "none",
                            justifyContent: "center",
                          }}
                        >
                          {menus.map((item) => {
                            if (item.submenu) {
                              return (
                                <SubMenu
                                  className="main_menu_item"
                                  key={item.key}
                                  title={item.title}
                                >
                                  {item.submenu.map((sub) => {
                                    if (sub.link.startsWith("https")) {
                                      return (
                                        <Menu.Item
                                          style={{
                                            backgroundColor: "transparent",
                                          }}
                                          className="main_menu_item"
                                          key={sub.key}
                                        >
                                          <a href={sub.link}>{sub.title}</a>
                                        </Menu.Item>
                                      );
                                    } else {
                                      return (
                                        <Menu.Item
                                          style={{
                                            backgroundColor: "transparent",
                                          }}
                                          className="main_menu_item"
                                          key={sub.key}
                                        >
                                          <Link to={sub.link}>{sub.title}</Link>
                                        </Menu.Item>
                                      );
                                    }
                                  })}
                                </SubMenu>
                              );
                            } else {
                              return (
                                <Menu.Item
                                  className="main_menu_item"
                                  key={item.key}
                                >
                                  <a href={item.link}>{item.title}</a>
                                </Menu.Item>
                              );
                            }
                          })}
                        </Menu>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={9}>
                    <ul className="nav col-lg-auto my-2 justify-content-center my-md-0 text-small">
                      <li>
                        <a
                          href="tel: 8615842599957"
                          className="nav-link text-white"
                          rel="noreferrer"
                          target={"_blank"}
                        >
                          <i className="fa-solid fa-phone"></i>
                          Phone: +86 15842599957
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
              {/* <Col span={24}>
                                <AppRoute {...props} />
                            </Col> */}
            </Row>
          </Col>
        </Row>
        <h1>
          <a
            href="/"
            onClick={function (e) {
              e.preventDefault();
              this.props.onChangePage();
            }.bind(this)}
          >
            {this.props.title}
          </a>
        </h1>
        <p>{this.props.sub}</p>
      </header>
    );
  }
}

export default Header;
